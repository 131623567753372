import * as React from "react";
import { Link } from "gatsby";

import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function BirdStealingBread() {
  return (
    <Layout>
      <Helmet title="Bird Stealing Bread | Iron & Wine" />

      <h1>Bird Stealing Bread</h1>

      <h2>Iron & Wine</h2>

      <h4>Key: C</h4>

      <h4>Tempo: 120</h4>

      <p>Non-ASCAP</p>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>
            <Ch>C</Ch>Tell me baby tell <Ch>G</Ch>me
          </p>
          <p>
            Are you <Ch>C</Ch>still on the stoop
          </p>
          <p>
            Watching the windows <Ch>G</Ch>close
          </p>
          <p>
            <Ch>C</Ch>I've not seen seen you lately<Ch>G</Ch>
          </p>
          <p>
            On the <Ch>C</Ch>street, by the beach
          </p>
          <p>
            Or places we used to <Ch>G</Ch>go
          </p>
        </Verse>

        <Chorus>
          <p>
            <Ch>C</Ch>I've a picture of you
          </p>
          <p>
            On our <Ch>Dsus2</Ch>favorite day by the <Ch>G</Ch>seaside
          </p>
          <p>
            There's a <Ch>C</Ch>bird stealing bread
          </p>
          <p>
            That I <Ch>Dsus2</Ch>brought out from under my <Ch>G</Ch>nose
          </p>
        </Chorus>

        <Verse>
          <p>
            <Ch>C</Ch>Tell me baby tell <Ch>G</Ch>me
          </p>
          <p>
            Does his <Ch>C</Ch>company make
          </p>
          <p>
            Light of a rainy <Ch>G</Ch>day
          </p>
          <p>
            <Ch>C</Ch>How I've missed you lately<Ch>G</Ch>
          </p>
          <p>
            And the <Ch>C</Ch>way we would speak
          </p>
          <p>
            And all that we wouldn't <Ch>G</Ch>say
          </p>
        </Verse>

        <Chorus>
          <p>
            <Ch>C</Ch>Do his hands in your hair
          </p>
          <p>
            Feel a <Ch>Dsus2</Ch>lot like a thing you believe <Ch>G</Ch>in
          </p>
          <p>
            Or a <Ch>C</Ch>bit like a bird stealing <Ch>Dsus2</Ch>bread
          </p>
          <p>
            Out from under your <Ch>G</Ch>nose
          </p>
        </Chorus>

        <Verse>
          <p>
            <Ch>C</Ch>Tell me baby tell <Ch>G</Ch>me
          </p>
          <p>
            Do you <Ch>C</Ch>carry the words
          </p>
          <p>
            Around like a key or <Ch>G</Ch>change
          </p>
          <p>
            <Ch>C</Ch>I've been thinking lately<Ch>G</Ch>
          </p>
          <p>
            of a <Ch>C</Ch>night on the stoop
          </p>
          <p>
            and all that we wouldn't <Ch>G</Ch>say
          </p>
        </Verse>

        <Chorus>
          <p>
            <Ch>C</Ch>If I see you again
          </p>
          <p>
            On the <Ch>Dsus2</Ch>street, by the beach
          </p>
          <p>
            In the <Ch>G</Ch>evening
          </p>
          <p>
            <Ch>C</Ch>Will you fly like a
          </p>
          <p>
            bird stealing <Ch>Dsus2</Ch>bread
          </p>
          <p>
            Out from under my <Ch>G</Ch>nose
          </p>
        </Chorus>
      </Columns>
    </Layout>
  );
}


